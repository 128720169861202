<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div v-if="companies.length > 0" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b card-stretch">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title">
              <span class="card-label font-weight-bolder text-dark"
                >All Companies</span
              >
            </h3>
            <div class="card-toolbar">
              <router-link
                to="/companies/new"
                class="btn btn-mc font-weight-bolder"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  />
                </span>
                Add New Company
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-head-bg table-vertical-center"
              >
                <thead>
                  <tr class="bg-gray-100 text-left">
                    <th style="min-width: 250px" class="pl-7">
                      <span @click="orderCompanyByName" class="cursor-pointer">
                        <span
                          class="pt-2"
                          :class="{ 'text-primary': nameOrder.isActive }"
                        >
                          Company</span
                        >
                        <span
                          class="svg-icon svg-icon-sm  pl-2"
                          :class="{
                            'svg-icon-primary': nameOrder.isActive
                          }"
                        >
                          <inline-svg
                            v-if="nameOrder.query === 'asc'"
                            src="media/svg/icons/Navigation/Down-2.svg"
                          />
                          <inline-svg
                            v-else
                            src="media/svg/icons/Navigation/Up-2.svg"
                          />
                        </span>
                      </span>
                    </th>
                    <th style="min-width: 110px">Address & Delivery address</th>
                    <th style="min-width: 110px">Is Verified?</th>
                    <th style="min-width: 110px" class="pr-7 text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(company, key) in companies" :key="key">
                    <td class="py-4" :class="{ 'border-top-0': key === 0 }">
                      <router-link
                        :to="'/companies/edit/' + company.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ company.name }}</router-link
                      >
                      <div>
                        <span class="font-weight-bolder">OIB: </span>
                        <span class="text-muted font-weight-bold">{{
                          company.oib
                        }}</span>
                      </div>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ company.address }}</span
                      >
                      <span class="text-muted font-weight-bold">{{
                        company.deliveryAddress
                      }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        v-if="company.isVerified"
                        class="label label-lg label-light-success label-inline"
                        >Yes</span
                      >
                      <span
                        v-else
                        class="label label-lg label-light-danger label-inline"
                        >No</span
                      >
                    </td>
                    <td
                      class="pr-0 text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <a
                        href="#"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/General/Settings-1.svg"
                          />
                        </span>
                      </a>
                      <router-link
                        :to="'/companies/edit/' + company.id"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Communication/Write.svg"
                          />
                        </span>
                      </router-link>
                      <button
                        @click="removeCompany(company.id)"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="media/svg/icons/General/Trash.svg" />
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" type="grow"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'ListCompanies',
  data() {
    return {
      companies: [],
      view: {
        previous: null,
        first: '?page=1',
        last: null,
        next: null
      },
      isLoaded: false,
      nameOrder: {
        query: 'asc',
        isActive: false
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Companies', route: '/companies/list' }
    ])

    this.getCompanies(
      '/companies',
      `?order[name]=${this.nameOrder.query}&properties[]=id&properties[]=name&properties[]=oib&properties[]=address&properties[]=deliveryAddress&properties[]=isVerified&itemsPerPage=500`
    ).then(({ data }) => {
      this.companies = [...data['hydra:member']]
      this.isLoaded = true
    })
  },
  methods: {
    async getCompanies(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async deleteCompany(id) {
      try {
        return await ApiService.delete('/companies/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    removeCompany(id) {
      const confirmation = confirm('You want delete this company?')
      if (confirmation === false) return

      this.deleteCompany(id).then(({ status }) => {
        if (status === 204) {
          this.companies = this.companies.filter(company => company.id !== id)
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Company success deleted from database'
          })
        }
      })
    },
    orderCompanyByName() {
      this.nameOrder.isActive = true
      if (this.nameOrder.query === 'asc') {
        this.nameOrder.query = 'desc'
      } else {
        this.nameOrder.query = 'asc'
      }

      this.isLoaded = false
      this.getCompanies(
        '/companies',
        `?order[name]=${this.nameOrder.query}&itemsPerPage=500`
      ).then(({ data }) => {
        this.companies = []
        this.companies = [...data['hydra:member']]
        this.isLoaded = true
      })
    }
  }
}
</script>
